<template>
    <div class="container">
        <crud ref="crud" :options="options"></crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'league.MerchantType.add',
                addPerm: 'league_MerchantType_add',
                editUrl: 'league.MerchantType.edit',
                editPerm: 'league_MerchantType_edit',
                delUrl: 'league.MerchantType.delete',
                delPerm: 'league_MerchantType_delete',
                listUrl: 'league.MerchantType.page',
                formLabelWidth: '100px',
                columns: [
                    {prop: "name", label: "名称", required: true},
                    {
                        prop: "cover", label: "图标", required: true, type: 'image', width: 72,
                        html: ({row}) => {
                            return row.cover ? `<a target="_blank" href="${row.cover}"><img class="list-user-avatar" src="${row.cover}" alt="加载失败"/></a>` : null
                        },
                        tip: '图片大小建议尺寸96x96'
                    },
                    {prop: "sort", label: "排序", type: "number", required: true, value: 0},
                    {prop: "remark", label: "备注",}
                ],
            }
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>
